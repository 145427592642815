<template>
  <div>
    <v-row class="mt-4" dense>
      <v-col cols="12" md="1">
        <v-btn icon @click="actionLeft">
          <v-icon>{{ iconLeft }}</v-icon>
        </v-btn>
      </v-col>

      <v-col cols="12" md="3">
        <v-select
          v-model="type"
          :items="types"
          outlined
          dense
          hide-details
          class="ma-2"
          label="type"
        ></v-select>
      </v-col>

      <v-col cols="12" md="3">
        <v-select
          v-model="mode"
          :items="modes"
          dense
          outlined
          hide-details
          label="event-overlap-mode"
          class="ma-2"
        ></v-select>
      </v-col>

      <v-col cols="12" md="3">
        <v-select
          v-model="weekday"
          :items="weekdays"
          outlined
          dense
          hide-details
          label="weekdays"
          class="ma-2"
        ></v-select>
      </v-col>

      <v-col cols="12" md="1">
        <v-btn icon @click="actionRight">
          <v-icon>{{ iconRight }}</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-sheet :height="height">
      <v-calendar
        ref="calendar"
        v-model="value"
        :weekdays="weekday"
        :type="type"
        :events="events"
        :event-more="eventMore"
        :event-overlap-mode="mode"
        :event-overlap-threshold="30"
        @click:event="performClickAction"
        @click:date="viewDay"
        @click:more="viewDay"
        @contextmenu:event="showContextMenu"
      >
        <template v-slot:event="{ eventParsed, event }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <!-- class="event" -->
              <div v-on="on">
                <!--  {{ getParsedDate(event) }}-->
                <!-- - -->
                {{ event.name }}
              </div>
            </template>
            <span>{{ event.tooltip }}</span>
          </v-tooltip>
        </template>
      </v-calendar>
    </v-sheet>

    <base-context-menu
      ref="menu"
      :contextMenuItems="contextMenuItems"
      :right-clicked-item="rightClickedEvent"
      @onContextAction="performAction"
    >
    </base-context-menu>
  </div>
</template>

<script>
import { iconChevronLeft, iconChevronRight } from "@/design/icon/iconConst";
import { eventNames } from "@/model/common/events/eventConst";
import { menuItemSettingsMixin } from "@/mixins/shared/base/settings/menuItemSettingsMixin";

export default {
  name: "BaseCalendar",
  components: {
    BaseContextMenu: () => import("@/components/shared/base/BaseContextMenu")
  },
  mixins: [menuItemSettingsMixin],
  props: {
    // Vuetify Calendar Content
    content: {
      type: Array,
      required: true
    },
    eventMore: {
      type: Boolean,
      default: true
    },
    // Calendar Height
    height: {
      type: Number,
      default: 650
    },
    contextMenuItems: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      iconLeft: iconChevronLeft,
      iconRight: iconChevronRight,
      type: "month",
      types: ["month", "week", "day", "4day"],
      weekday: [0, 1, 2, 3, 4, 5, 6],
      weekdays: [
        { text: "Sun - Sat", value: [0, 1, 2, 3, 4, 5, 6] },
        { text: "Mon - Sun", value: [1, 2, 3, 4, 5, 6, 0] },
        { text: "Mon - Fri", value: [1, 2, 3, 4, 5] },
        { text: "Mon, Wed, Fri", value: [1, 3, 5] }
      ],
      mode: "stack",
      modes: ["stack", "column"],
      value: "",
      contextMenu: false,
      contextMenuX: 0,
      contextMenuY: 0,
      rightClickedEvent: null
    };
  },
  computed: {
    events() {
      return this.content ?? [];
    }
  },
  methods: {
    /**
     * Jump to Day View in Calendar
     * @param event
     */
    viewDay(event) {
      this.value = event?.date ?? event?.day?.date;
      this.type = this.types[2];
    },

    /**
     * On Event Clicked
     * @param event
     */
    performClickAction(event) {
      this.$emit(eventNames.onEventClicked, event.event.id);
    },

    /**
     * Icon Previous Action
     */
    actionLeft() {
      this.$refs.calendar.prev();
    },

    /**
     * Icon Next Action
     */
    actionRight() {
      this.$refs.calendar.next();
    },

    /**
     * Get Calendar Display Date (Parsed)
     * @param event
     * @return {string}
     */
    getParsedDate(event) {
      return new Date(event.start).toLocaleString([], {
        hour: "2-digit",
        minute: "2-digit"
      });
    },

    /**
     * Context Menu Action
     * @param event
     */
    showContextMenu(event) {
      this.rightClickedEvent = event.event;
      this.onRightClickedEvent();
      this.$refs.menu.open(event.nativeEvent);
    },

    /**
     * Perform Context Menu Action
     * @param payload
     */
    performAction(payload) {
      if (payload.action.type) {
        this.type = payload.action.type;
      }
      this.$emit(eventNames.calendarContextAction, payload);
    },

    /**
     * When context menu is opened using right click
     */
    onRightClickedEvent() {
      const payload = {
        id: this.rightClickedEvent.id,
        calendarType: this.type
      };
      this.$emit(eventNames.onEventRightClicked, payload);
    }
  },
  watch: {
    events: {
      handler(events) {
        if (events.length > 0) {
          this.value = this.events[0].start;
        }
      },
      deep: true,
      immediate: true
    }
  }
};
</script>
